<template>
    <OModal ref="modalRef" >    
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="hstack gap-2">
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Edit Comment") }}
                        </h4>                        
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="alert alert-info mb-1 py-2" style="font-size: small;">
                        <div style="font-weight:600" class="mb-2">{{ $t("Write any comments in: ") }} {{ checkItemsDataObject.current.BaseLanguage }}</div>
                        <div>{{ $t("Translations will be generated asynchronous after saving.") }}</div>
                        <div>{{ $t("Until translations are generated - the original text will be shown") }}</div>                    
                    </div>

                    <div>
                        <label>{{ $t("Comments:") }}</label>
                        <OTextArea 
                            @blur="checkItemsDataObject.save();"
                            class="form-control"
                            style="max-height: 250px; overflow-y: auto; width:calc(100%); height:100px;" 
                            v-model="checkItemsDataObject.current.Comments" />                    
                    </div>                    
                </div>
                   
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal" aria-label="Close">{{ $t("Close") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
import { OModal, OTextArea } from 'o365-ui-components';
import { ref } from 'vue';

const modalRef = ref(null);

const props = defineProps({
    checkItemsDataObject: {
        type: Object,
        required: true,
        validator: (value) => value.fields.fieldExists("BaseLanguage") && value.fields.fieldExists("Comments")
    },
});

defineExpose({ show });

async function show() { 
    modalRef.value?.show();
}
</script>